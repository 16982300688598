import Consult from "../img/consultancy.jpeg";

function Consultancy() {
  return (
    <div className="center">
      <div className="info">
        <h3 className="step-head">Teşvik ve Hibe Danışmanlığı</h3>
        <p className="step-description">
          Farklı kamu kurum ve kuruluşları, işletme boyutu farketmeksizin
          firmaların innovasyona yönelmesi, Ar-Ge yatırımlarını arttırması, yeni
          pazarlara açılabilmesi gibi konulara yönelik hibe, kredi ve faiz
          destekleri vermektedir. Bu destekler sayesinde firmalar altyapılarını
          geliştirmekte, Ar-Ge projelerini hayata geçirmekte ya da farklı
          pazarlara açılarak yeni müşteriler elde edebilmektedir. Bu kapsamda
          şirketimiz profesyonel kadrosu ile TKDK, TÜBİTAK, KOSGEB, Kalkınma
          Ajansları gibi bütün kurum ve kuruluşlara yönelik kapsamlı bir proje
          geliştirme ve yönetimi danışmanlık hizmeti vermektedir.
        </p>
        <img src={Consult} className="step-img" alt="consultancy" />
        <p className="step-description">
          Proje sunarak hibe ve kredi desteği alabileceğiniz programlar aşağıda
          detaylandırılmıştır.
        </p>
        <h3 className="step-head">IPARD Kırsal Kalkınma Programı (TKDK)</h3>
        <p className="step-description">
          Avrupa Birliği (AB) tarafından aday ve potansiyel aday ülkelere destek
          olmak amacıyla oluşturulan, Katılım Öncesi Yardım Aracı'nın Kırsal
          Kalkınma bileşenidir. Tarım ve Kırsal Kalkınmayı Destekleme Kurumu
          (TKDK) aracılığı proje bazlı bir çok yatırım alanında hibe desteği
          verilmektedir. (tkdk.gov.tr)
        </p>
        <h3 className="step-head">Kalkınma Ajansı Projeleri</h3>
        <p className="step-description">
          Ülkemizde kamu, özel sektör ve STK arasındaki iş birliğini arttırmak,
          etkin kaynak kullanımını sağlamak ve yerel potansiyeli teşvik etmek
          amacıyla, bölgesel kalkınmayı hızlandırmak, sürdürülebilirlik
          sağlamak, bölgeler gelişmişlik farklarını azaltmak için oluşturulan
          Kalkınma Ajansları Destek Programları, Sanayi ve Teknoloji Bakanlığı
          koordinasyonunda yürütülen hibe programıdır. Türkiye’de bütün
          şehirleri kapsayan 26 bölgesel kalkınma ajansı ile ekonomik, sosyal,
          kırsal kalkınma, küçük ölçekli altyapı projeleri ve KOBİ mali destek
          gibi alt başlıkları olan hibe programlarında %75’e varan oranlarda
          destek sağlanmaktadır.
        </p>
        <h3 className="step-head">Avrupa Birliği Projeleri</h3>
        <p className="step-description">
          HORİZON 2020 AB’de AR-GE kapsamında yürütülen faaliyetlerin etkisinin
          artırılması hedefiyle, AB'de yürütülen tüm araştırma ve yenilik
          programlarının 2014-2020 yılları için bütüncül bir sistem altında
          toplamayı amaçlamaktadır. Horizon 2020 Programı Bilimsel Mükemmeliyet,
          Endüstriyel Liderlik ve Rekabetçilik ile Toplumsal Sorunlara Çözümler
          olmak üzere üç öncelik alanı altında yapılandırılmıştır. Desteklenen
          proje konuları;
        </p>
        <ul className="step-list">
          <li>Ar-Ge Çalışmaları</li>
          <li> Destek ve Koordinasyon Eylemleri</li>
          <li> Yenilik Hibeleri (Grants for Innovation)</li>
          <li> Ortak Fonlama (Co-Funding) Eylemleri</li>
          <li> KOBİ projeleri</li>
        </ul>
        <h3 className="step-head">KOSGEB Destekleri</h3>
        <ul className="step-list">
          <li>Girişimcilik Destek Programı</li>
          <li>Ar-Ge, Ür-Ge ve İnovasyon Destek Programı</li>
          <li>Teknoyatırım Destek Programı</li>
        </ul>
        <h3 className="step-head">TÜBİTAK Destekleri</h3>
        <ul className="step-list">
          <li>1507 - KOBİ Ar-Ge Başlangıç Destek Programı</li>
          <li>1501 - Sanayi Ar-Ge Projeleri Destekleme Programı</li>
          <li>1511 - Öncelikli Alanlar Destek Programı (HAMLE)</li>
        </ul>
        <h3 className="step-head">Ticaret Bakanlığı Destekleri</h3>
        <ul className="step-list">
          <li>Bilgisayar Oyunu / Mobil Uygulama Geliştirme Desteği</li>
          <li>Yurt Dışı Bilim, Marka ve Tanıtım Faaliyetleri Desteği</li>
          <li>Pazar Araştırması ve Pazara Giriş Desteği</li>
          <li>Yurt Dışında Gerçekleştirilen Fuar Katılımları Desteği</li>
          <li>Döviz Kazandırıcı Hizmet Ticareti Desteği</li>
          <li>Küresel Tedarik Zinciri (KTZ) Yetkinlik Projeleri Desteği</li>
          <li>Tasarım ve Ürün Geliştirme Proje Desteği</li>
        </ul>
        <h3 className="step-head">Sanayi ve Teknoloji Bakanlığı Destekleri</h3>
        <ul className="step-list">
          <li> Yatırım ve Teşvik Belgesi </li>
          <li>Teknolojik Ürün Yatırım Destek Programı (TEKNO YATIRIM)</li>
        </ul>
        <h3 className="step-head">İhracat Danışmanlığı</h3>
        <p className="step-description">
          Küreselleşen dünyada uluslararası pazarlara adım atarken doğru
          rehberlik ve stratejik bir yol haritası olmadan ilerlemek, iş
          dünyasının zorluklarına karşı büyük bir risk oluşturur. İhracat
          Danışmanlığı Hizmetleri olarak, işinizi küresel arenada büyütmenize
          döviz kazanmanıza yardımcı olmak için buradayız.
        </p>
        <div className="step-description">
          <li>İhracat Danışmanlığı Hizmetlerimiz</li>
          <li> Pazar Araştırması ve Analizi </li>
          <li>Strateji Geliştirme </li>
          <li> Ticari İstihbarat </li>
          <li> Dokümantasyon ve Gümrük İşlemleri </li>
          <li> Pazarlama ve Kurumsal İletişim</li>
        </div>
      </div>
    </div>
  );
}

export default Consultancy;
