import OilList from "../components/OilList";
import List from "../components/List";

function Encyclopedia() {
  return (
    <div>
      <List />
      <OilList />
    </div>
  );
}

export default Encyclopedia;
